import React, { useState } from 'react';
import { useForm } from '../forms/useForm';
import { useEffect } from 'react';
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../tokens/useToken';
import { Code500 } from '../../constant/globalMessage';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as XLSX from 'xlsx';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import radialimage from '../../assets/images/dashboard-2/radial-graph.png';
import ConfigDB from '../../data/customizer/config';
const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || ConfigDB.data.color.secondary_color;
const danger = localStorage.getItem('danger_color') || ConfigDB.data.color.danger_color;



export const useReportWorkOrderInvoice = () => {

    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [createSweet] = useSweetAlert();

    const [viewCompany, setViewCompany] = useState(true);
    const [reportCompany, setReportCompany] = useState(null);
    const [reportPatient, setReportPatient] = useState(null);
    const [reportGeneralPublic, setReportGeneralPublic] = useState(null);

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const currentDay = new Date().getDate();

    //*Filters
    const [filterPaid, setFilterPaid] = useState(false);
    const [filterInvoice, setFilterInvoice] = useState(false);
    const [filterCredit, setFilterCredit] = useState(false);
    const [filterEfectivo, setFilterEfectivo] = useState(false);
    const [filterTC, setFilterTC] = useState(false);
    const [filterTD, setFilterTD] = useState(false);
    const [filterTransferencia, setFilterTransferencia] = useState(false);


    const startDate = `${currentYear}-${currentMonth <= 9 ? '0' + currentMonth : currentMonth}-01`;

    const endDate = `${currentYear}-${currentMonth <= 9 ? '0' + currentMonth : currentMonth}-${currentDay <= 9 ? '0' + currentDay : currentDay}`;

    const [formQuery, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        start_date: startDate,
        end_date: endDate,
        id_branch: 0
    });



    const {
        start_date, end_date, id_branch
    } = formQuery;

    useEffect(async () => {
        await GetReportInvoice();
    }, []);

    //METHODS
    const GetReportInvoice = async () => {

        let method = `Reports/GetReportInvoices/${start_date}/${end_date}`;

        if (id_branch !== 0 && id_branch.value !== undefined) {
            method = method + `&id_branch=${id_branch.value}`;
        }

        const request = await _handleRequest("GET", method, "Reporte global");
        console.log(request);

        if (request !== null) {

            setReportCompany(request.reportCompany);
            setReportPatient(request.reportPatient);
            setReportGeneralPublic(request.reportInvoiceGeneralPublic);
        }
    }

    const handleChangeView = () => {
        setViewCompany(!viewCompany);
    }


    //


    const RiskBarPatient = {
        series: [reportPatient?.total_invoices, reportPatient?.not_invoices],
        options: {
            chart: {
                height: 350,
                type: 'radialBar',
                offsetY: -10,
            },

            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    inverseOrder: true,
                    hollow: {
                        margin: 5,
                        size: '60%',
                        image: radialimage,
                        imageWidth: 140,
                        imageHeight: 140,
                        imageClipped: false,
                    },
                    track: {
                        opacity: 0.4,
                        colors: secondary
                    },
                    dataLabels: {
                        show: true,
                        enabled: true,
                        value: {
                            show: true,
                            fontSize: '14px',
                            formatter: function (val) {
                                return val + ' '
                            }
                        },
                        textAnchor: 'middle',
                        distributed: false,
                        offsetX: 0,
                        offsetY: 0,

                        style: {
                            fontSize: '14px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fill: ['#2b2b2b'],

                        },
                        total: {
                            show: true,
                            label: (reportPatient?.total_work_orders === 0 ? "Facturas pendientes" :  "Total De Admisiones"),
                            color: danger,
                       
                            formatter: function (w) {
                                return (reportPatient?.total_invoices === 0 ? reportPatient?.not_invoices :  reportPatient?.total_work_orders )
                            }
                        }
                    },
                }
            },

            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    shadeIntensity: 0.15,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, reportPatient?.total_work_orders],
                    gradientToColors: ['#a927f9'],
                    type: 'horizontal'
                },
            },
            stroke: {
                dashArray: 15,
                strokecolor: ['#ffffff']
            },

            labels: ['Facturadas', 'Pendientes'],
            colors: [secondary, primary,danger],
        }
    }

    //*MAKE EXCEL

    const MakeExcelParticular = ( outValidity = false, payment = false) => {
        let _report = [];

        reportPatient?.reportDetail?.length > 0 && (
            (filterPaid || filterInvoice
                ? reportPatient.reportDetail
                    .filter(_filter => {
                        if (outValidity && _filter?.is_invoiced === true) {
                            return true;
                        }
                        if (payment && _filter?.is_invoiced === false) {
                            return true;
                        }
                        return false;
                    })
                : reportPatient.reportDetail
            ).map((_order, _index) => (
                _report.push({
                    "Nim": _order?.nim,
                    "Sucursal": _order?.name_branch,
                    "Nombre del Paciente": _order?.name_patient,
                    "Recepción": _order?.reception_date,
                    "Pagado": _order?.is_full_paid ? "Si" : "No",
                    "Facturado": _order?.is_invoiced ? "Si" : "Pendiente",
                    "Serie": _order.listInvoice.length === 0 ? "-----" : _order.listInvoice[0].invoiceSerie,
                    "Folio": _order.listInvoice.length === 0 ? "-----" : _order.listInvoice[0].folio,
                    "Total": _order?.total,
                    "Debe": _order?.credit,
                    "Metódo de Pago":
                    _order?.pay_methods?.length > 0 && (
                        (() => {
                            const lastPaymentMethod = _order.pay_methods[_order.pay_methods.length - 1];

                            switch (lastPaymentMethod) {
                                case 1:
                                    return "Efectivo";
                                case 2:
                                    return "Tarjeta de Crédito";
                                case 4:
                                    return "Tarjeta de Débito";
                                case 8:
                                    return "Transferencia";
                                default:
                                    return "Crédito";
                            }
                        })()
                    )
                    
              

                })
            ))
        )
        const worksheet = XLSX.utils.json_to_sheet(_report);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = 'indicadores_facturacion.xlsx';

        if (typeof window !== 'undefined' && window.navigator && window.navigator.msSaveOrOpenBlob) {
            // Para Internet Explorer
            window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
        } else {
            // Para otros navegadores
            const url = window.URL.createObjectURL(dataBlob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }


    const _handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, _handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, Code500);
        }
        else {
            createSweet("warning", "warning", nameMethod, response?.data?.msg);
        }

        return null;
    }


    return {
        loading,

        start_date, end_date, id_branch,

        viewCompany,

        handleInputChange, handleChangeView,

        reportCompany, reportPatient, reportGeneralPublic,
        
        GetReportInvoice,MakeExcelParticular,

        RiskBarPatient,
        //*Filters
        filterPaid,setFilterPaid,
        filterInvoice,setFilterInvoice,
        filterCredit,setFilterCredit,
        filterEfectivo,setFilterEfectivo,
        filterTC,setFilterTC,
        filterTD,setFilterTD,
        filterTransferencia, setFilterTransferencia





    }

}
