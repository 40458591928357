import React, { Fragment } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row, Table ,Badge } from 'reactstrap'
import ReactApexChart from 'react-apexcharts'
import { useReportWorkOrderInvoice } from '../../hooks/reports/useReportWorkOrderInvoice'

export const IndexInvoiceReport = () => {

    const {
        loading,

        start_date, end_date, id_branch,

        handleInputChange, reportPatient, 

        GetReportInvoice,  RiskBarPatient,MakeExcelParticular,

        //*Filters
        filterPaid,setFilterPaid,
        filterInvoice,setFilterInvoice,
        filterCredit,setFilterCredit,
        filterEfectivo,setFilterEfectivo,
        filterTC,setFilterTC,
        filterTD,setFilterTD,
        filterTransferencia, setFilterTransferencia

    } = useReportWorkOrderInvoice();


    return (
        <Fragment>
            <Container fluid={true} className='p-l-0 p-r-0 mb-5'>
                <Row className="size-column">
                    <Col xl={12} className="risk-col box-col-12">
                        <Card>
                            <CardHeader className="card-no-border p-3 mb-2">
                                <div className='ribbon ribbon-clip ribbon-secondary-Particular'>
                                    {"Particular"}
                                </div>
                                <div className="card-header-right">
                                    <ul className="list-unstyled card-option">
                                        <li><i className="fa fa-spin fa-spinner"></i></li>  
                                    </ul>
                                </div>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <Row>
                                    <Col sm="12" md="6" lg="5" className=''>
                                    <div className="goal-chart">
                                        <ReactApexChart id="riskfactorchart" options={RiskBarPatient.options} series={RiskBarPatient.series} type='radialBar' height={350} />
                                    </div>
                                    <div className="goal-end-point text-center">
                                        <h6 className="typeParticular">{"Pacientes Particulares"}</h6>                                                
                                    </div>
                                    <hr />                                    
                                    </Col>
                                    <Col sm="12" md="6" lg="6">
                                    <Row>
                                        <Col sm="1"></Col>
                                        <Col sm="3" className='p-2'>
                                            <label className='txt-secondary' >Fecha Inicio</label>
                                                <Input type='date' size={"sm"} className='round b-r-5 border-1' name='start_date' value={start_date} onChange={(e) => handleInputChange(e)}>
                                                    <option value="">Selecciona una sucursal</option>
                                                </Input>
                                            </Col>
                                            <Col sm="3" className='p-2'>
                                            <label className='txt-secondary' >Fecha Final</label>
                                                <Input type='date' size={"sm"} className='round b-r-5 border-1' name='end_date' value={end_date} onChange={(e) => handleInputChange(e)}>
                                                    <option value="">Selecciona una sucursal</option>
                                                </Input>
                                            </Col>
                                            <Col sm="3" className='text-center p-3'> <br /> 
                                                <Button color='info' size='sm' onClick={GetReportInvoice}>Filtrar
                                                </Button>
                                            </Col>
                                        </Row> <br />
                                        <Col sm="12" className='card-Information' style={{textAlign: "center"}} > <br />
                                            <Row>
                                            <Col sm="4">
                                            <label className='label-text-Information f-14' >Total de Admisiones</label>
                                        </Col>
                                        <Col sm="4">
                                        <label className='label-text-Information f-14'>Total Facturados</label>
                                        </Col>
                                        <Col sm="4">
                                        <label className='label-text-Information f-14'>Total sin Facturar</label>
                                        </Col>
                                            </Row>
                                            <br />
                                            <hr />
                                            <br />
                                            <Row>
                                            <Col sm="4">
                                            <label className='label-text-Information f-18' >{reportPatient?.total_work_orders}</label>
                                        </Col>
                                        <Col sm="4">
                                        <label className='label-text-Information f-18'>{reportPatient?.total_invoices}</label>
                                        </Col>
                                        <Col sm="4">
                                        <label className='label-text-Information f-18'>{reportPatient?.not_invoices}</label>
                                        </Col>
                                            </Row>                        
                                        </Col>                                  
                                    </Col>
                                </Row>                       
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className='m-b-20'>
                    <Col sm="12">
                       <Card style={{ height: '440px', overflowY: "scroll", scrollBehavior: "smooth" }}>
                        <CardBody>
                            <div className='text-right'>
                              <Label type="text" className={`f-w-600 f-12 badge badge${filterEfectivo ? "" : "-light"}-primary`} style={{ cursor: "pointer" }} onClick={() => { setFilterEfectivo(!filterEfectivo) }} > Efectivo </Label>
                              <Label type="text" className={`f-w-600 f-12 badge badge${filterCredit ? "" : "-light"}-primary`} style={{ cursor: "pointer" }} onClick={() => { setFilterCredit(!filterCredit) }} > Credito</Label>
                              <Label type="text" className={`f-w-600 f-12 badge badge${filterTC ? "" : "-light"}-primary`} style={{ cursor: "pointer" }} onClick={() => { setFilterTC(!filterTC) }} > Tarjeta de Crédito </Label>
                              <Label type="text" className={`f-w-600 f-12 badge badge${filterTD ? "" : "-light"}-primary`} style={{ cursor: "pointer" }} onClick={() => { setFilterTD(!filterTD) }} > Tarjeta de Débito </Label>
                              <Label type="text" className={`f-w-600 f-12 badge badge${filterTransferencia ? "" : "-light"}-primary`} style={{ cursor: "pointer" }} onClick={() => { setFilterTransferencia(!filterTransferencia) }} > Transeferencia </Label>
                              <Label type="text" className={`f-w-600 f-12 badge badge${filterPaid ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPaid(!filterPaid) }}>Facturado </Label>
                              <Label type="text" className={`f-w-600 f-12 badge badge${filterInvoice ? "" : "-light"}-danger`} style={{ cursor: "pointer" }} onClick={() => { setFilterInvoice(!filterInvoice) }} > Pendiente en Facturar </Label>
                              <Label type="text" className={`f-w-600 f-12 badge badge-success`} style={{ cursor: "pointer" }} onClick={() => { MakeExcelParticular(filterPaid,filterInvoice) }} >Descargar excel</Label>
                            </div>
                            <div className='table-responsive'>
                                <Table className=' m-b-5'>
                                    <thead className="thead-Particular"> 
                                        <tr style={{ backgroundColor: "#033d5b" }} >
                                            <th className='labeltheadInvoice'>#</th>
                                            <th className='labeltheadInvoice'>NIM</th>
                                            <th className='labeltheadInvoice'>Paciente</th>
                                            <th className='labeltheadInvoice'>Sucursal</th>
                                            <th className='labeltheadInvoice'>Recepción</th>
                                            <th className='labeltheadInvoice'>¿Pagada?</th>
                                            <th className='labeltheadInvoice'>¿Facturada?</th>
                                            <th className='labeltheadInvoice'>Serie</th>
                                            <th className='labeltheadInvoice'>Folio</th>
                                            <th className='labeltheadInvoice'>Total</th>
                                            <th className='labeltheadInvoice'>Debe</th>
                                            <th className='labeltheadInvoice'>Método de Pago</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reportPatient?.reportDetail?.length > 0 && (
                                            (filterPaid || filterInvoice || filterCredit || filterEfectivo || filterTC || filterTD || filterTransferencia
                                            ? reportPatient.reportDetail.filter(_filter => {
                                                const { is_invoiced, pay_methods} = _filter;
                                                const lastPaymentMethod = pay_methods[pay_methods.length - 1];

                                                return (
                                                    (filterPaid && is_invoiced === true) ||
                                                    (filterInvoice && is_invoiced === false) ||
                                                    (filterEfectivo && lastPaymentMethod === 1) ||
                                                    (filterCredit && lastPaymentMethod === 10) ||
                                                    (filterTC && lastPaymentMethod === 2) ||
                                                    (filterTD && lastPaymentMethod === 4) ||
                                                    (filterTransferencia && lastPaymentMethod === 8)
                                                );
                                                })
                                            : reportPatient.reportDetail
                                            ).map((_order, _index) => (
                                            <tr key={_order?.id_work_order}  className={_order.cancell === true ? "colorTable" : "" }    >
                                                <td>{_index + 1}</td>
                                                <td>{_order?.nim}</td>
                                                <td>{_order?.name_patient}</td>
                                                <td>{_order?.name_branch}</td>
                                                <td style={{textAlign: "center"}}>
                                                <Button style={{ pointerEvents: "none" }} color='info' outline className={`btn btn-pill btn-xs`}>{_order?.reception_date}</Button> <br />
                                                <label className='txt-primary ' style={{ fontSize: "10px" }} > {_order?.dateFech}</label>
                                                </td>
                                                <td>
                                                <Label type="text" className={`f-w-400 f-12 badge badge-${_order?.is_full_paid === true ? 'success' : 'danger'}`}>
                                                    {_order?.is_full_paid ? "Si" : "No"}
                                                </Label>
                                                </td>
                                                <td>
                                                <Label
                                                    type="text"
                                                    className={`f-w-400 f-12 badge badge-${_order?.is_invoiced === true ? 'secondary' : 'danger'}`}
                                                >
                                                    {_order?.is_invoiced ? "Facturado" : "Pendiente"}
                                                </Label>
                                                </td>
                                                <td>
                                                    {
                                                         _order?.listInvoice?.length  === 0 ? <b>------</b>
                                                         :
                                                         <div>
                                                            {
                                                            _order?.listInvoice?.length > 0 &&  _order?.listInvoice?.map((_find) => {
                                                                return(
                                                                    <Badge className={_find.isGeneralPublic === true ? "f-12 badge-secondaryInvoice pointerCursor"
                                                                    : "f-12 primary pointerCursor"
                                                                   }
                                                                    >
                                                                       { _find.invoiceSerie
                                                                     }
                                                                   </Badge>
                                                                )
                                                            } )        
                                                            } 
                                                         </div>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                         _order?.listInvoice?.length  === 0 ? <b>------</b>
                                                         :
                                                         <div>
                                                             {
                                                            _order?.listInvoice?.length > 0 &&  _order?.listInvoice?.map((_find) => {
                                                                return(
                                                                    <b
                                                                    type="text"
                                                                    className={_find.isGeneralPublic === false ? "txt-secondary" : "labelInvoiceR"}
                                                                    >
                                                                    {_find?.folio }
                                                                </b>
                                                                )
                                                            } )        
                                                            }  
                                                         </div>
                                                    }
                                                </td>
                                                <td className={_order.is_invoiced == true ? 'f-w-700' : 'font-danger f-w-700'}>${_order?.total}</td>
                                                <td>${_order?.credit}</td>
                                                <td>
                                                <label className='f-w-600 f-12 badge badge-light-secondary'>
                                                    {_order?.pay_methods?.length > 0 && (
                                                    (() => {
                                                        const lastPaymentMethod = _order.pay_methods[_order.pay_methods.length - 1];

                                                        switch (lastPaymentMethod) {
                                                        case 1:
                                                            return "Efectivo";
                                                        case 2:
                                                            return "T.C";
                                                        case 4:
                                                            return "T.D";
                                                        case 8:
                                                            return "Transferencia";
                                                        case 12:
                                                            return "Mercado Pago";
                                                        case 30:
                                                             return "Cupón";
                                                        case 31:
                                                            return "Cupón";
                                                        default:
                                                            return "Crédito";
                                                        }
                                                    })()
                                                    )}
                                                </label>
                                                </td>
                                            </tr>
                                            ))
                                        )}
                                 </tbody>
                                </Table>
                            </div>
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
