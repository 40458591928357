import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row, Collapse, Input, Table } from 'reactstrap';
import { useForm } from '../../hooks/forms/useForm';
import { sendRequest } from '../../hooks/requests/useRequest';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import Chart from 'react-apexcharts'
import { useHistory } from 'react-router-dom';
import { validarSesion } from '../../hooks/tokens/useToken';
import './StyleResult.css'
import { ComponentChart } from './ComponentChart';
import { ComponentChartHistories } from './ComponentChartHistories';
import { ConfigServer } from "../../data/config";
import { ComponentImagenology } from './ComponentImagenology';
import { ComponentImagenologyProfile } from './ComponentImagenologyProfile';
import { ComponentProfileChart } from './ComponentProfileChart';
import { ComponentAntibiograma } from './ComponentAntibiograma';

export const ResultsFromNimPublic = () => {

    //#region login Microsite

    const { nim, password } = useParams();
    const [createSweet] = useSweetAlert();
    const [loadingForm, setLoadingForm] = useState(false);
    const [histories, setHistories] = useState([]);
    const history = useHistory();


    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        input_nim: "",
        input_password: ""
    });

    const { input_nim, input_password } = formValues;


    useEffect(() => {
        if (password === undefined && nim === undefined) {
            handleUpdateValues({
                input_nim: "",
                input_password: ""
            })
        } else if (password !== "-" && nim !== "-") {
            handleUpdateValues({
                input_nim: nim,
                input_password: password
            })
        }

    }, []);

    //#endregion Login Microsite

    //#region State Result Nim

    const [workOrderPrintResultCaptureView, setWorkOrderPrintResultCaptureView] = useState({});
    const [lista, setLista] = useState({});
    const [listaExamenes, setListaExamenes] = useState([]);
    const [listaProfiles, setListaProfiles] = useState([]);
    const [chartOptions, setChartOptions] = useState({});
    const [series, setSeries] = useState([])
    const [idd, setIdd] = useState(0)
    
    //#endregion State Result Nim

    //#region Result From Nim
    const validateData = async (e) => {
        setLoadingForm(true);

        e.preventDefault();
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, `PrintResult/GetResultFromNimAndPassword/${input_nim}/${input_password}`);
        console.log(respuesta);

        if (respuesta.code === 200) {

            let idP = []
            let idPacien = 0;
            setHistories(respuesta.data.histories);
            setWorkOrderPrintResultCaptureView(respuesta.data.workOrderPrintResultCaptureView);
            setLista(respuesta.data.lista);
            setListaExamenes(respuesta.data.lista_examenes);
            setListaProfiles(respuesta.data.lista)
            setViewData(true);
            idPacien = respuesta.data.workOrderPrintResultCaptureView.id_patient;
            setIdd(idPacien)
            //  stattus = respuesta.data.workOrderPrintResultCaptureView.arrayStatus;

            respuesta.data.lista_examenes.forEach(element => {
                element.listAnalytes.forEach(obj => {
                    idP = obj.id_analyte
                });
            });
            chartCaptureresul(idP, idPacien)
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, validateData);

        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Resultados", "Ocurrio un error en el servidor");
        }
        else {
            if (respuesta.code === 404) {
                createSweet("error", "warning", "NIM incorrecto");

                handleUpdateValues({
                    input_nim: "",
                    input_password: "",
                });
                setLoadingForm(false);


            }
            else {
                createSweet("error", "warning", "Resultados", respuesta.data.msg);

                handleUpdateValues({
                    input_nim: "",
                    input_password: "",
                });
                setLoadingForm(false);

            }
        }
    }

    //#endregion Result Nim

    //#region Chart

    const chartCaptureresul = async (idP, idPacien) => {

        let requestOptions = {
            method: 'GET'
        };


        const respuesta = await sendRequest(requestOptions, `Exam/charthistoricAnalitValues/${idPacien}/${idP}`);
        if (respuesta.code === 200) {

            let options = {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        tools: {
                            download: false
                        },
                    },
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                },
                colors: ['#0079C7'],
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    curve: 'smooth'
                },
                grid: {
                    row: {
                        colors: ['#46AFE5', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: respuesta.data.categories,
                },
                yaxis: {
                    title: {
                        //    text: (analyte.name_unit) datos mostrados en la misma linea de trabajo
                    },
                }
            }
            setChartOptions(options);
            setSeries(respuesta.data.series)
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, chartCaptureresul);
        }
        else if (respuesta.code === 500) {
        }
        else {
        }
    }

    //#endregion Chart

    //#region Dowload Result

    const downloadSheetResults = async (e, nim_local = "") => {
        let body = {
            nim: input_nim,
            password: input_password,
            type: "",
            microsite: true,
        }

        if (nim_local.length > 0) {
            body.nim = nim_local;
            body.type = "NP";
        }

        let token = localStorage.getItem("token");

        let raw = JSON.stringify(body);


        await fetch(ConfigServer.serverUrl + "/api/PrintResult/DownloadSheetResultsPatient", {
            method: 'POST',
            body: raw,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "resultados_medicos.pdf")
                document.body.appendChild(link);
                link.style.display = 'none';
                link.click();
            });
    }

    const [viewData, setViewData] = useState(false);

    const reload = () => {
        window.location.reload(true);
    }


    //#endregion DowLoad Resul

    //#region Profiles
    let profiles = Object.values(listaProfiles)

    let profilesHistories = Object.values(histories)

    //#endregion Profiles


    //#region historial Patient

    const [historialCollapse, setHistorialCollapse] = useState(false);

    //#endregion historial patient





    return (
        <div className="b-center bg-size"  >
            <Container className="" fluid={true} style={{ backgroundColor: "#F8F9FA", boxSizing: "border-box" }}>
                {
                    viewData ?
                        <>
                            <div className='divHeader p-r-0 p-l-0'>
                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <Row>
                                        <Col xs="2" sm="12" md="12" lg="3" xl="3" className='p-1' >
                                            <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} alt="helenLABS" style={{ width: "15%" }} className='imgHlabs' />
                                        </Col>
                                        <Col xs="7" sm="12" md="12" lg="6" xl="6" className='p-l-0 p-r-0 ' style={{ textAlign: "-webkit-center" }}> <br />
                                            <label className='TitleCaptureResults '>Consulta de resultados</label>
                                        </Col>
                                        <Col xs="3" sm="12" md="12" lg="3" xl="3" className='p-1'>
                                            {/* <img src={require("../../assets/images/loginHelenLabs/a.png")} alt="helenLABS" style={{ width: "30%" }} className='logoD' /> */}
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12"> <br />
                                <Row>
                                    <Col sm="12">
                                        <Row>
                                            <Col xs="12" sm="12" md="12" lg="6" xl="6">
                                                <Card className='shadow'>
                                                    <Col sm="12" className='p-1' >
                                                        <Row>
                                                            <Col xs="5" sm="5" md="5" lg="5" xl="5" className='p-r-0'>
                                                                <div className="">
                                                                    {
                                                                        (workOrderPrintResultCaptureView.gender) === "Femenino" ?
                                                                            <img className="" src={require("../../assets/images/loginHelenLabs/User/UserWoman.svg")} style={{ width: "90%" }} />
                                                                            :
                                                                            <img className="" src={require("../../assets/images/loginHelenLabs/User/UserMen.svg")} style={{ width: "90%" }} />
                                                                    }
                                                                </div>
                                                            </Col>
                                                            <Col xs="7" sm="5" md="5" lg="5" xl="5" className='p-l-0'> <br />
                                                                <div>
                                                                    <label className='txt-info f-18'>{workOrderPrintResultCaptureView.name_patient}</label> <br />
                                                                    <label className='f-14'>{workOrderPrintResultCaptureView.nim}</label>
                                                                </div> <br />
                                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                    <Row>

                                                                        <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-l-0 p-r-0' >
                                                                            {
                                                                                workOrderPrintResultCaptureView.status === true ?
                                                                                    <Button
                                                                                        outline
                                                                                        color='primary'
                                                                                        size='xs'
                                                                                        onClick={downloadSheetResults} >Descargar
                                                                                    </Button>
                                                                                    :
                                                                                    ""


                                                                            }

                                                                        </Col>
                                                                        <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-l-0 p-r-0' >
                                                                            <Button
                                                                                outline
                                                                                color='primary'
                                                                                size='xs'
                                                                                onClick={reload}
                                                                            >
                                                                                Cerrar Sesión
                                                                            </Button>
                                                                        </Col>

                                                                    </Row>
                                                                </Col> <br />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Card>
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="6" xl="6" className='p-l-0 p-r-0'>
                                                <Card className='shadow'>
                                                    <CardHeader className='p-2 bg-info'>
                                                        <label htmlFor="">Datos Generales</label>

                                                    </CardHeader>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-l-0 p-r-0' >
                                                        <Table className='table table-xs p-0'>
                                                            <tbody className='f-10'>
                                                                <tr className='p-0'>
                                                                    <td className='p-t-1 p-b-0 ' style={{ "vertical-align": "middle", width: "40%" }} >
                                                                        <i className="fa fa-child f-20 IconPatient"></i><label className='txt-primary'>Genero</label>
                                                                    </td>
                                                                    <td className='p-t-1 p-b-0 ' style={{ "vertical-align": "middle", width: "60%" }} >
                                                                        <label className='f-10'>{workOrderPrintResultCaptureView.gender}</label>
                                                                    </td>
                                                                </tr>
                                                                <tr className='p-0'>
                                                                    <td className='p-t-1 p-b-0'>
                                                                        <i className="fa fa-child f-20 IconPatient"></i><label className='txt-primary'>Edad</label>
                                                                    </td>
                                                                    <td className='p-t-1 p-b-0'>
                                                                        <label className='f-10'>{workOrderPrintResultCaptureView.age}</label>
                                                                    </td>
                                                                </tr>
                                                                <tr className='p-0'>
                                                                    <td className='p-t-1 p-b-0'>
                                                                        <i className="fa fa-phone f-20 IconPatient"></i><label className='txt-primary'>Telefono</label>
                                                                    </td>
                                                                    <td className='p-t-1 p-b-0 '>
                                                                        <label className='f-10'>{workOrderPrintResultCaptureView.phone}</label>
                                                                    </td>
                                                                </tr>
                                                                <tr className='p-0'>
                                                                    <td className='p-t-1 p-b-0'>
                                                                        <i className="fa fa-child f-20 IconPatient"></i><label className='txt-primary'>Medico</label>
                                                                    </td>
                                                                    <td className='p-t-1 p-b-0'>
                                                                        <label className='f-10'>{workOrderPrintResultCaptureView.name_doctor}</label>
                                                                    </td>
                                                                </tr>
                                                                <tr className='p-0'>
                                                                    <td className='p-t-1 p-b-0'>
                                                                        <i className="fa fa-tachometer f-20 IconPatient"></i><label className='txt-primary'>recepción</label>
                                                                    </td>
                                                                    <td className='p-t-1 p-b-0'>
                                                                        <label className='f-10'>{workOrderPrintResultCaptureView.date_creation}</label>
                                                                    </td>
                                                                </tr>
                                                                <tr className='p-0'>
                                                                    <td className='p-t-1 p-b-0'>
                                                                        <i className="fa fa-tachometer f-20 IconPatient"></i><label className='txt-primary'>Consulta</label>
                                                                    </td>
                                                                    <td className='p-t-1 p-b-0'>
                                                                        <label className='f-10'>{workOrderPrintResultCaptureView.date_release}</label>
                                                                    </td>
                                                                </tr>

                                                            </tbody>

                                                        </Table> <br />
                                                    </Col>

                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-l-0 p-r-0' >
                                        <Card className='shadow'>
                                            <CardHeader className='bg-primary p-2'>
                                                <label>Mis resultados</label>
                                            </CardHeader>
                                            <CardBody>
                                                {
                                                    (workOrderPrintResultCaptureView.status === true) ?
                                                        <div>
                                                            <Row>

                                                                {
                                                                    profiles.length > 0 &&
                                                                    profiles.map((obj, key) => {
                                                                        return (
                                                                            <Col key={key} sm="12">
                                                                                {
                                                                                    (obj.is_imagenology) ?
                                                                                        <div>
                                                                                            <ComponentImagenologyProfile

                                                                                            />
                                                                                        </div>
                                                                                        :
                                                                                        <div>
                                                                                            <Row>
                                                                                                <Col sm="12">
                                                                                                    {
                                                                                                        obj.isProfile === true ?
                                                                                                            <b className='font-prueba'><u>{obj.name}</u></b>
                                                                                                            :
                                                                                                            <b className='font-prueba'><u>{obj.name}</u></b>
                                                                                                    }
                                                                                                    <br />
                                                                                                    <span style={{ textTransform: 'uppercase' }} className='font-method1 F-10'>MÉTODO: {obj.method}</span>
                                                                                                    <br />
                                                                                                    <hr />
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <div className='table-responsive'>
                                                                                                <Table className='table-xs'>
                                                                                                    <thead style={{ textAlign: "-webkit-center" }} >
                                                                                                        <tr className='border-bottom-info'>
                                                                                                            <th>GRAFICA</th>
                                                                                                            <th>PRUEBA</th>
                                                                                                            <th style={{ textAlign: "-webkit-center" }} >RESULTADO</th>
                                                                                                            <th >UNIDAD</th>
                                                                                                            <th style={{ textAlign: "-webkit-center" }} >INTERVALO BIOLÓGICO DE REFERENCIA</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        {
                                                                                                            obj.individualExams.map((a, k) => {
                                                                                                                console.log(a);
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            a.analytes.map((analyteFinally, llave) => {
                                                                                                                                console.log(analyteFinally);
                                                                                                                                if (analyteFinally.print_pdf_analyte) {
                                                                                                                                    return (
                                                                                                                                        <ComponentProfileChart
                                                                                                                                            index={llave}
                                                                                                                                            analyte2={analyteFinally}
                                                                                                                                            method={obj.method}
                                                                                                                                            {
                                                                                                                                            ...{
                                                                                                                                                idPatient: idd
                                                                                                                                            }
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </tbody>
                                                                                                </Table>

                                                                                            </div>

                                                                                        </div>

                                                                                }
                                                                            </Col>
                                                                        )
                                                                    })
                                                                }
                                                            </Row>

                                                            <br /><br />
                                                            <Row>
                                                                {
                                                                    listaExamenes.length > 0 &&
                                                                    listaExamenes.map((analyte, index) => {
                                                                        return <Col key={index} sm="12" >
                                                                            {
                                                                                (analyte.is_imagenology === true) ?
                                                                                    <div>
                                                                                        <ComponentImagenology
                                                                                            item={analyte}
                                                                                            key={index}
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    <div>
                                                                                        <Row>
                                                                                            <Col sm="12">

                                                                                                <b className='font-prueba'>{analyte.name}</b>

                                                                                                <br />
                                                                                                <span style={{ textTransform: 'uppercase' }} className='font-method1 f-10'>metodo: {analyte.method}</span>
                                                                                                <br />
                                                                                                <br /> <br />
                                                                                            </Col>
                                                                                            <Col sm="12">
                                                                                                {
                                                                                                    (analyte.listIsolatedMicroorganims === null)
                                                                                                        ?
                                                                                                        ""
                                                                                                        :
                                                                                                        <div>
                                                                                                            {
                                                                                                                analyte.listIsolatedMicroorganims.map((o, i) => {
                                                                                                                    return (
                                                                                                                        <Col sm="12" key={i}>
                                                                                                                            <Row>
                                                                                                                                <Col sm="4">
                                                                                                                                    <label className=''>MICROORGANISMOS AISLADOS</label>
                                                                                                                                </Col>
                                                                                                                                <Col sm="4">
                                                                                                                                    <label className=''>{o.name_bacterium}</label>
                                                                                                                                </Col>
                                                                                                                                <Col sm="4">
                                                                                                                                    <label className=''>{o.result}</label>
                                                                                                                                </Col>
                                                                                                                            </Row>

                                                                                                                        </Col>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                }
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <div className="table-responsive">
                                                                                            <Table className='table-xs'>
                                                                                                <thead style={{ textAlign: "-webkit-center" }} >
                                                                                                    <tr className='border-bottom-info'>
                                                                                                        <th>GRAFICA</th>
                                                                                                        <th>PRUEBA</th>
                                                                                                        <th style={{ textAlign: "-webkit-center" }} >RESULTADO</th>
                                                                                                        <th >UNIDAD</th>
                                                                                                        <th style={{ textAlign: "-webkit-center" }} >INTERVALO BIOLÓGICO DE REFERENCIA</th>
                                                                                                    </tr>

                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        analyte.listAnalytes.map((analyte2, index2) => {
                                                                                                            console.log(analyte2);
                                                                                                            if (analyte2.print_pdf_analyte) {
                                                                                                                return <ComponentChart
                                                                                                                    index={index2}
                                                                                                                    analyte2={analyte2}
                                                                                                                    {
                                                                                                                    ...{
                                                                                                                        idPatient: idd
                                                                                                                    }
                                                                                                                    }
                                                                                                                //idPatient={idd}
                                                                                                                />
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                </tbody>
                                                                                            </Table>
                                                                                        </div> <br /> <br />
                                                                                        <div>
                                                                                            {
                                                                                                (analyte.listMicAntibiograms === null) ?
                                                                                                    ""
                                                                                                    :
                                                                                                    <>
                                                                                                        <b>Antibiograma:</b>
                                                                                                        <div style={{ textAlign: "-webkit-center" }} >
                                                                                                            <Col sm="12">
                                                                                                                <Row>
                                                                                                                    <Col sm="4">
                                                                                                                        {
                                                                                                                            analyte.listMicAntibiograms.map((obj, lla) => {
                                                                                                                                return (
                                                                                                                                    <b>{obj.name_mic_bacterium}</b>
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </Col>
                                                                                                                    <Col sm="4">
                                                                                                                        <b>Interpretación</b>
                                                                                                                    </Col>
                                                                                                                    <Col sm="4">
                                                                                                                        <b>CMI</b>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </Col>
                                                                                                            {
                                                                                                                analyte.listMicAntibiograms.map((anti, keyy) => {
                                                                                                                    return (
                                                                                                                        <ComponentAntibiograma
                                                                                                                            index={keyy}
                                                                                                                            analyte4={anti}
                                                                                                                        />
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    </>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                        </Col>
                                                                    })
                                                                }
                                                            </Row>


                                                        </div>
                                                        :
                                                        <div style={{ textAlign: "-webkit-center" }}>
                                                            <img src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "30%" }} alt="" /> <br />
                                                            <label className='f-w-600 f-14 badge badge-light-primary'>Sus resultados aun no estan disponibles</label>

                                                        </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>


                                    <Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <Row className="position-sticky" style={{ top: 140 }}>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <Card>
                                                    <CardHeader className='bg-primary'>
                                                        <h5 className="mb-0">
                                                            <Button color="link pl-0" data-toggle="collapse" onClick={() => setHistorialCollapse(!historialCollapse)}
                                                                data-target="#collapseicon" aria-expanded={historialCollapse} aria-controls="collapseicon"><i className="fa fa-folder-open-o f-24"></i>&nbsp;<label className='labelFilter'>Resultados Anteriores</label></Button>
                                                        </h5>
                                                    </CardHeader>
                                                    <Collapse isOpen={historialCollapse}>
                                                        <CardBody className='p-2'>
                                                            <Col sm="12">
                                                                {
                                                                    profilesHistories.length === 0 ?
                                                                        <div style={{ textAlign: "-webkit-center" }}>
                                                                            <img src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "20%" }} alt="" /> <br />
                                                                            <label className='f-w-600 f-14 badge badge-light-primary'>No cuenta con un historial Clinico</label>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            {
                                                                                profilesHistories.map((exams, llave) => {
                                                                                    let prueba = Object.values(exams.lista)
                                                                                    return (
                                                                                        < div className='b-t-primary b-b-primary"'>
                                                                                            <label className="f-w-600 f-14 badge badge-light-primary labelDate">Fecha: <b>{exams.date}</b></label> <br />
                                                                                            <button color='primary' size='xs' type='button' class="btn btn-outline-primary btn-xs pagebutton" onClick={(e) => downloadSheetResults(e, exams.nim)}>DESCARGAR PDF&nbsp;<i className="icofont icofont-download-alt"> </i></button>

                                                                                            {
                                                                                                exams.lista_examenes.length === 0 ?
                                                                                                    ""
                                                                                                    :
                                                                                                    <div key={llave}>
                                                                                                        {
                                                                                                            exams.lista_examenes.map((item, keyy) => {
                                                                                                                return (
                                                                                                                    <Col sm="12" key={keyy}>
                                                                                                                        {
                                                                                                                            (item.is_imagenology) ?
                                                                                                                                <div>
                                                                                                                                    <ComponentImagenology
                                                                                                                                        item={item}
                                                                                                                                        key={keyy}
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                                :
                                                                                                                                <div >
                                                                                                                                    <Row>
                                                                                                                                        <Col sm="12"> <br />
                                                                                                                                            <b className='font-prueba'>{item.name}</b> <br />
                                                                                                                                            <span style={{ textTransform: 'uppercase' }} className='font-method1 f-10'>metodo: {item.method}</span>
                                                                                                                                        </Col>
                                                                                                                                    </Row>
                                                                                                                                    <div className='table-responsive'>
                                                                                                                                        <Table className='table-xs'>
                                                                                                                                            <thead style={{ textAlign: "-webkit-center" }} >
                                                                                                                                                <tr className=''>
                                                                                                                                                    <th>GRAFICA</th>
                                                                                                                                                    <th>PRUEBA</th>
                                                                                                                                                    <th style={{ textAlign: "-webkit-center" }} >RESULTADO</th>
                                                                                                                                                    <th >UNIDAD</th>
                                                                                                                                                    <th style={{ textAlign: "-webkit-center" }} >INTERVALO BIOLÓGICO DE REFERENCIA</th>
                                                                                                                                                </tr>
                                                                                                                                            </thead>
                                                                                                                                            <tbody>
                                                                                                                                                {
                                                                                                                                                    item.listAnalytes.map((analyte3, index3) => {
                                                                                                                                                        if (analyte3.print_pdf_analyte) {
                                                                                                                                                            return <ComponentChartHistories
                                                                                                                                                                index3={index3}
                                                                                                                                                                analyte3={analyte3}
                                                                                                                                                                {
                                                                                                                                                                ...{
                                                                                                                                                                    idPatient: idd
                                                                                                                                                                }
                                                                                                                                                                }
                                                                                                                                                            //idPatient={idd}
                                                                                                                                                            />
                                                                                                                                                        }
                                                                                                                                                    })
                                                                                                                                                }
                                                                                                                                            </tbody>
                                                                                                                                        </Table>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        {
                                                                                                                                            (item.listMicAntibiograms === null) ?
                                                                                                                                                ""
                                                                                                                                                :
                                                                                                                                                <>
                                                                                                                                                    <b>Antibiograma:</b>
                                                                                                                                                    <div style={{ textAlign: "-webkit-center" }} >
                                                                                                                                                        <Col sm="12">
                                                                                                                                                            <Row>
                                                                                                                                                                <Col sm="4">
                                                                                                                                                                    {
                                                                                                                                                                        item.listMicAntibiograms.map((obj, kke) => {
                                                                                                                                                                            return (
                                                                                                                                                                                <b>{obj.name_mic_bacterium}</b>
                                                                                                                                                                            )
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                </Col>
                                                                                                                                                                <Col sm="4">
                                                                                                                                                                    <b>Interpretación</b>
                                                                                                                                                                </Col>
                                                                                                                                                                <Col sm="4">
                                                                                                                                                                    <b>CMI</b>
                                                                                                                                                                </Col>
                                                                                                                                                            </Row>
                                                                                                                                                        </Col>
                                                                                                                                                        {
                                                                                                                                                            item.listMicAntibiograms.map((anti, keyy) => {
                                                                                                                                                                return (
                                                                                                                                                                    <ComponentAntibiograma
                                                                                                                                                                        index={keyy}
                                                                                                                                                                        analyte4={anti}
                                                                                                                                                                    />
                                                                                                                                                                )
                                                                                                                                                            })
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                </>
                                                                                                                                        }
                                                                                                                                    </div>

                                                                                                                                </div>

                                                                                                                        }
                                                                                                                    </Col>
                                                                                                                )
                                                                                                            })
                                                                                                        }


                                                                                                    </div>
                                                                                            }
                                                                                            {
                                                                                                exams.lista.length === 0 ?
                                                                                                    ""
                                                                                                    :
                                                                                                    <div key={llave}>

                                                                                                        {
                                                                                                            prueba.map((obj, ind) => {
                                                                                                                return (
                                                                                                                    <Col sm="12" key={ind}>
                                                                                                                        {
                                                                                                                            (obj.is_imagenology) ?
                                                                                                                                <div>
                                                                                                                                    <ComponentImagenology
                                                                                                                                        item={obj}
                                                                                                                                        key={ind}
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                                :
                                                                                                                                <div className='b-t-primary b-b-primary"' >
                                                                                                                                    <Row>
                                                                                                                                        <Col sm="12"> <br />
                                                                                                                                            {
                                                                                                                                                obj.isProfile === true ?
                                                                                                                                                    <b className='font-prueba'><u>{obj.name}</u></b>
                                                                                                                                                    :
                                                                                                                                                    <b className='font-prueba'><u>{obj.name}</u></b>
                                                                                                                                            }
                                                                                                                                            <br />
                                                                                                                                            <span style={{ textTransform: 'uppercase' }} className='font-method1 f-10'>metodo: {obj.method}</span>
                                                                                                                                            <br />
                                                                                                                                        </Col>
                                                                                                                                    </Row>
                                                                                                                                    <div className='table-responsive'>
                                                                                                                                        <Table className='table-xs'>
                                                                                                                                            <thead style={{ textAlign: "-webkit-center" }} >
                                                                                                                                                <tr className=''>
                                                                                                                                                    <th>GRAFICA</th>
                                                                                                                                                    <th>PRUEBA</th>
                                                                                                                                                    <th style={{ textAlign: "-webkit-center" }} >RESULTADO</th>
                                                                                                                                                    <th >UNIDAD</th>
                                                                                                                                                    <th style={{ textAlign: "-webkit-center" }} >INTERVALO BIOLÓGICO DE REFERENCIA</th>
                                                                                                                                                </tr>
                                                                                                                                            </thead>
                                                                                                                                            <tbody>
                                                                                                                                                {
                                                                                                                                                    obj.individualExams.map((a, k) => {
                                                                                                                                                        return (
                                                                                                                                                            <>
                                                                                                                                                                {
                                                                                                                                                                    a.analytes.map((analyteFinally, llave) => {
                                                                                                                                                                        if (analyteFinally.print_pdf_analyte) {
                                                                                                                                                                            return (
                                                                                                                                                                                <ComponentProfileChart
                                                                                                                                                                                    index={llave}
                                                                                                                                                                                    analyte2={analyteFinally}
                                                                                                                                                                                    method={obj.method}
                                                                                                                                                                                    idPatient={idd}
                                                                                                                                                                                />
                                                                                                                                                                            )
                                                                                                                                                                        }
                                                                                                                                                                    })
                                                                                                                                                                }
                                                                                                                                                            </>
                                                                                                                                                        )
                                                                                                                                                    })
                                                                                                                                                }
                                                                                                                                            </tbody>
                                                                                                                                        </Table>
                                                                                                                                    </div>
                                                                                                                                    <br /> <br />
                                                                                                                                    <hr />
                                                                                                                                </div>
                                                                                                                        }
                                                                                                                    </Col>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                }
                                                            </Col>

                                                        </CardBody>
                                                    </Collapse>

                                                </Card>


                                            </Col>

                                        </Row>
                                    </Col>




                                </Row>
                            </Col>
                        </>
                        :
                        <>

                            <Col sm="12" className='resultados-mostrados' >
                                <Row>
                                    <Col className='col1Image p-l-0 p-r-0 ' xs="12" sm="12" md="7" lg="8" xl="8">
                                        <div>
                                            <img src={require("../../assets/images/loginHelenLabs/ResultsNIm/LoginResultsFromNim.svg")} style={{ width: "100%" }} alt="" />
                                        </div>
                                    </Col>
                                    <Col xs="12" sm="12" md="5" lg="4" xl="4" >
                                        <div className='hijo'>

                                            <Row>
                                                <Col xs="12" sm="12" md="11" lg="12" xl="9" className='text-center'>
                                                    <p className="title m-b-0" style={{ fontWeight: "lighter" }}> <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} alt="helenLogo" style={{ width: "10%" }} /> &nbsp;&nbsp;&nbsp;<label className='LabelLogin p-1 p-t-0'>HELEN</label> <label className='LabelLogin2 p-1 p-t-0'>LABS</label></p>
                                                </Col>
                                            </Row> <br />
                                            <Col xs="12" sm="12" md="11" lg="12" xl="9" className='text-center'>
                                                <img className="b-r-10"
                                                    src={ConfigServer.serverUrl + "/api/Configuration/Logo"}
                                                    style={{ width: "50%" }}
                                                    alt="" />
                                                <div className="media-body p-2 text-white"> <i className="middle fa fa-angle-down"></i> <br /></div>
                                            </Col>
                                            <Col xs="12" sm="12" md="9" lg="12" xl="9" style={{ textAlign: "-webkit-center" }}>
                                                <h5>Acceso a tus resultados</h5>
                                            </Col> <br />
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-r-0'>
                                                <Form className="theme-form" method='get' onSubmit={validateData}>
                                                    <Col xs="12" sm="12" md="9" lg="11" xl="9" className='p-r-0'>
                                                        <FormGroup>
                                                            <Label className="labelMenu">{`Ingresa tu nim`}</Label>
                                                            <Input className="form-control-sm input-air-primary btn-pill" type="text" value={input_nim} name="input_nim" onChange={handleInputChange} style={{ border: "#0079C7 2px solid" }} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12" sm="12" md="9" lg="11" xl="9" className='p-r-0'>
                                                        <FormGroup>
                                                            <Label className="labelMenu">{`Ingresa tu contraseña`}</Label>
                                                            <Input className="form-control-sm input-air-primary btn-pill" type="text" value={input_password} name="input_password" onChange={handleInputChange} style={{ border: "#0079C7 2px solid" }} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12" sm="12" md="9" lg="11" xl="9" style={{ textAlign: "-webkit-center" }} className="p-r-0">
                                                        <Button type='submit' color='primary' className={loadingForm ? "disabled progress-bar-animated progress-bar-striped btn-pill" : "btn-pill"} >
                                                            Validar datos
                                                        </Button>
                                                    </Col>
                                                </Form>
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                }
            </Container >
        </div >
    )
}

